import { type UTPlacesSetWorkPlanOrigin } from 'owa-unified-telemetry';

export default function getPlacesSetWorkPlanOrigin(
    entryPoint: 'PlacesNativeApp' | 'CalendarPeekView' | 'MyDayPane'
) {
    switch (entryPoint) {
        case 'PlacesNativeApp':
            return 'PlacesNativeApp';
        case 'CalendarPeekView':
            return 'CalendarPeekView';
        case 'MyDayPane':
            return 'MyDayPane';
    }
}
